var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"pr-1 pl-6",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-col',[_c('v-row',{staticClass:"mt-1 mr-0"},[_c('p',{staticClass:"text-h6 text-bold"},[_vm._v("Project Locations")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-4",attrs:{"rounded":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.openCreateGeolocationForm}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" NEW ")],1)],1),_c('v-text-field',{staticClass:"ml-n3",attrs:{"hide-details":"","label":"Search"},model:{value:(_vm.geolocationToSearch),callback:function ($$v) {_vm.geolocationToSearch=$$v},expression:"geolocationToSearch"}}),_c('v-progress-linear',{staticClass:"mt-0 ml-n3",attrs:{"active":_vm.loading,"indeterminate":"","color":"primary"}}),_c('v-col',{staticClass:"d-flex align-center flex-wrap",staticStyle:{"row-gap":"20px","max-height":"90vh","overflow-y":"auto","margin-top":"5px"}},_vm._l((_vm.geolocationsFiltered),function(location,index){return _c('v-card',{key:index,staticClass:"mr-1",style:({
                            width: '100%',
                        }),attrs:{"flat":""}},[(location)?_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openEditGeolocationForm(location)}}},[_c('div',{staticClass:"d-flex flex-column justify-space-around me-4"},[_c('v-row',[_c('v-icon',[_vm._v("mdi-map-marker")]),_c('v-col',[_c('span',{staticClass:"font-weight-bold text-singleline"},[_vm._v(" "+_vm._s(location.name)+" ")]),_c('span',{staticClass:"text-caption text-singleline"},[_vm._v(" "+_vm._s(location.notes || '')+" ")])])],1)],1)]):_vm._e()])}),1)],1)],1),_c('v-col',{staticClass:"pr-1 pl-6",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-col',[_c('v-row',{staticClass:"mt-1 mr-0"},[_c('p',{staticClass:"text-h6 text-bold"},[_vm._v("External Users")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-4",attrs:{"rounded":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.openCreateExternalUserForm}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" NEW ")],1)],1),_c('v-text-field',{staticClass:"ml-n3",attrs:{"hide-details":"","label":"Search"},model:{value:(_vm.externalUserToSearch),callback:function ($$v) {_vm.externalUserToSearch=$$v},expression:"externalUserToSearch"}}),_c('v-progress-linear',{staticClass:"mt-0 ml-n3",attrs:{"active":_vm.loading,"indeterminate":"","color":"primary"}}),_c('v-col',{staticClass:"d-flex align-center flex-wrap",staticStyle:{"row-gap":"20px","max-height":"34vh","overflow-y":"auto","margin-top":"5px"}},_vm._l((_vm.externalUsersFiltered),function(user,index){return _c('v-card',{key:index,staticClass:"mr-1",style:({
                            width: '100%',
                            backgroundColor: !user.active
                                ? '#fffdf2'
                                : 'white',
                        }),attrs:{"flat":""}},[(user)?_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openEditExternalUserForm(user)}}},[_c('v-avatar',{staticClass:"mr-1",attrs:{"color":"grey lighten-4","size":"34"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(user.name .split(' ') .map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null ) .join('') .toUpperCase())+" ")])]),_c('div',{staticClass:"d-flex flex-column justify-space-around me-4"},[_c('span',{staticClass:"font-weight-bold text-singleline"},[_vm._v(" "+_vm._s(user.name)+" "+_vm._s(user.role ? `(${user.role})` : '')+" ")]),_c('span',{staticClass:"text-caption text-singleline"},[_vm._v(" "+_vm._s(user.phone)+" ")]),_c('span',{staticClass:"text-caption text-singleline"},[_vm._v(" "+_vm._s(user.email)+" ")])])],1):_vm._e()])}),1)],1)],1)],1),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","width":"700"},model:{value:(_vm.extenarlUserDialog),callback:function ($$v) {_vm.extenarlUserDialog=$$v},expression:"extenarlUserDialog"}},[(_vm.extenarlUserDialog)?_c('ExternalUserForm',{attrs:{"projectId":_vm.project.id,"user":_vm.selectedExternalUser,"createForm":_vm.createForm},on:{"closeDialog":_vm.closeExternalUserForm,"addExternalUser":_vm.addExternalUser,"replaceExternalUser":_vm.replaceExternalUser,"removeExternalUser":_vm.removeExternalUser}}):_vm._e()],1),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","width":"700"},model:{value:(_vm.geolocationDialog),callback:function ($$v) {_vm.geolocationDialog=$$v},expression:"geolocationDialog"}},[(_vm.geolocationDialog)?_c('GeolocationForm',{attrs:{"projectId":_vm.project.id,"loc":_vm.selectedGeolocation,"createForm":_vm.createForm},on:{"closeDialog":_vm.closeGeolocationForm,"addProjectLocation":_vm.addGeolocation,"replaceProjectLocation":_vm.replaceGeolocation,"removeProjectLocation":_vm.removeGeolocation}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }