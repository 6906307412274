<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: 'project-reports',
                    params: {
                        setting,
                    },
                }"
            >
                REPORTS
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-text class="overflow-y-auto" :style="`height: ${height}px`">
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'ProjectSettings',
    props: {
        setting: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        show: false,
        loading: false,
        height: 0,
    }),
    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = containerHeight - tabsHeight
        },
    },
}
</script>

<style></style>
