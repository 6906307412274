<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="itemsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            :height="$vuetify.breakpoint.mdAndDown ? '66vh' : '68vh'"
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3 py-0">
                        <h1 class="mr-4 my-0">WEEKLY REPORTS</h1>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            @click="openCreateReport()"
                            class="mr-4 mb-3"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                        <v-btn
                            rounded
                            color="primary"
                            @click="openComponentToSendLink"
                            class="mr-4 mb-3"
                        >
                            <v-icon>
                                mdi-send-variant
                            </v-icon>
                            {{
                                $vuetify.breakpoint.smAndDown ? '' : 'SEND LINK'
                            }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!-- HEADERS -->
            <template v-slot:[`header.id`]="{ header }">
                <v-text-field :label="header.text" v-model="idToFilter" />
            </template>
            <template v-slot:[`header.date`]="{ header }">
                <v-text-field :label="header.text" v-model="dateToFilter" />
            </template>
            <template v-slot:[`header.projectLocation`]="{ header }">
                <v-text-field :label="header.text" v-model="locationToFilter" />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field :label="header.text" v-model="userToFilter" />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.id`]="{ item }">
                <p class="my-0">
                    {{ item.number }}
                </p>
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
                <p class="my-0">
                    {{
                        formatDate(
                            item.createdOn._seconds || item.createdOn.seconds
                        )
                    }}
                </p>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <p class="my-0">
                    {{ getUserName(item.createdBy) }}
                </p>
            </template>
            <template v-slot:[`item.projectLocation`]="{ item }">
                <p class="my-0">
                    {{ item.projectLocation.name }}
                </p>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openSendReport(item)">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                    <v-btn small icon @click="openReportDetails(item)">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!-- QR DIALOG -->
        <v-dialog v-model="qrDialog" persistent max-width="340px">
            <v-card>
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="qrDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Field Report QR
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider class="mx-5" />
                <v-card-text>
                    <vue-qr :text="link" :size="300"></vue-qr>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- REPORT DIALOG -->
        <v-dialog v-model="reportDialog" persistent fullscreen>
            <DailyReportForm
                v-if="reportDialog"
                :project="project"
                :externalUsers="externalUsers"
                :projectLocations="projectLocations"
                :selectedReport="selectedReport"
                :createForm="createForm"
                :type="'weekly'"
                @closeDialog="closeReportDialog"
                @addReport="addReport"
                @replaceReport="replaceReport"
                @removeReport="removeReport"
            />
        </v-dialog>
        <!-- SEND DIALOG -->
        <v-dialog v-model="sendDialog" persistent max-width="500px">
            <SendReport
                v-if="sendDialog"
                :project="project"
                :selectedReport="selectedReport"
                :users="users"
                :externalUsers="externalUsers"
                @closeDialog="closeSendDialog"
            />
        </v-dialog>
        <!--SEND LINK TO CREATE REPORTS-->
        <v-dialog v-model="sendLinkDialog" persistent max-width="500px">
            <SendLinkToCreateReports
                v-if="sendLinkDialog"
                :project="project"
                :type="'weekly'"
                @close="closeComponentToSendLink"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import vueQr from 'vue-qr'
import moment from 'moment'
import _ from 'lodash'
import {
    generateHTMLReport,
    loadPDFPreview,
} from '@/components/Timesheets/dailyReport.js'
import { storage } from '@/services/firebase'
import { dataURLtoFile } from '@/helpers/base64ToFile.js'

export default {
    name: 'WeeklyReports',
    props: {
        project: {
            type: Object,
            Required: true,
            default: () => ({}),
        },
    },
    components: {
        vueQr,
        DailyReportForm: () =>
            import('@/components/Timesheets/DailyReportForm.vue'),
        SendReport: () => import('@/components/Timesheets/SendReport.vue'),
        SendLinkToCreateReports: () =>
            import('@/components/Timesheets/SendLinkToCreateReports.vue'),
    },
    data: () => ({
        sendLinkDialog: false,
        loading: false,
        workLogs: [],
        sendDialog: false,
        headers: [
            // {
            //     text: 'ID',
            //     value: 'id',
            //     align: 'center',
            //     sortable: false,
            //     width: 80,
            // },
            {
                text: 'REPORT DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT LOCATION',
                value: 'projectLocation',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED ON',
                value: 'createdOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        link: null,
        qrDialog: false,
        items: [],
        locationToFilter: undefined,
        userToFilter: undefined,
        idToFilter: undefined,
        dateToFilter: undefined,
        reportDialog: false,
        dates: [],
        weatherData: null,
        externalUsers: [],
        selectedReport: {},
        createForm: false,
        projectLocations: [],
        reports: [],
        users: [],
    }),
    computed: {
        itemsFiltered() {
            let conditions = []
            if (this.dateToFilter) {
                conditions.push(this.filterByDate)
            }
            if (this.userToFilter) {
                conditions.push(this.filterByUser)
            }
            if (this.locationToFilter) {
                conditions.push(this.filterByLocation)
            }
            if (this.idToFilter) {
                conditions.push(this.filterById)
            }
            if (conditions.length > 0) {
                return this.reports.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            this.sort()
            return this.reports
        },
    },
    async mounted() {
        try {
            this.loading = true
            await this.getData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeComponentToSendLink() {
            this.sendLinkDialog = false
        },
        openComponentToSendLink() {
            this.sendLinkDialog = true
        },
        async getData() {
            try {
                const {
                    data: { users },
                } = await API.getLiteUsers()
                this.users = users
                this.externalUsers = await API.getExternalUsers({
                    projectId: this.project.id,
                })
                this.externalUsers.forEach(user => {
                    user.formattedTimesheets = []
                    if (user.timesheet) {
                        Object.keys(user.timesheet).forEach(date => {
                            user.timesheet[date].forEach(entry => {
                                if (entry.type == 'weekly') {
                                    user.formattedTimesheets.push({
                                        date,
                                        ...entry,
                                    })
                                }
                            })
                        })
                    }
                })
                this.projectLocations = await API.getProjectLocations({
                    projectId: this.project.id,
                })
                this.reports = await API.getWeeklyReports({
                    projectId: this.project.id,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        sort() {
            this.reports.sort(
                (a, b) =>
                    (b.createdOn._seconds || b.createdOn.seconds) -
                    (a.createdOn._seconds || a.createdOn.seconds)
            )
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD hh:mm A')
        },
        filterByDate(item) {
            return (
                item.date &&
                item.date
                    .toLowerCase()
                    .includes(this.dateToFilter.toLowerCase())
            )
        },
        filterByUser(item) {
            return (
                item.createdBy &&
                this.getUserName(item.createdBy)
                    .toLowerCase()
                    .includes(this.userToFilter.toLowerCase())
            )
        },
        filterById(item) {
            return (
                item.number &&
                item.number
                    .toLowerCase()
                    .includes(this.idToFilter.toLowerCase())
            )
        },
        filterByLocation(item) {
            return (
                item.projectLocation &&
                item.projectLocation.name
                    .toLowerCase()
                    .includes(this.locationToFilter.toLowerCase())
            )
        },
        openCreateReport() {
            this.selectedReport = {}
            this.createForm = true
            this.reportDialog = true
        },
        openEditReport(report) {
            this.selectedReport = _.cloneDeep(report)
            this.createForm = false
            this.reportDialog = true
        },
        closeReportDialog() {
            this.reportDialog = false
            this.selectedReport = {}
            this.createForm = false
        },
        addReport(report) {
            this.reports.push(report)
        },
        replaceReport(report) {
            const index = this.reports.findIndex(r => r.id == report.id)
            if (index > -1) {
                this.reports[index] = _.cloneDeep(report)
            } else {
                this.reports.push(report)
            }
        },
        removeReport(report) {
            const index = this.reports.findIndex(r => r.id == report.id)
            if (index > -1) {
                this.reports.splice(index, 1)
            }
        },
        getUserName(id) {
            const user = this.users.find(u => u.id == id)
            if (user) {
                return user.name
            }
            return ''
        },
        openSendReport(report) {
            this.selectedReport = _.cloneDeep(report)
            this.sendDialog = true
        },
        closeSendDialog() {
            this.sendDialog = false
            this.selectedReport = {}
        },
        async openReportDetails(report) {
            try {
                this.loading = true
                const reportToShow = _.cloneDeep(report)
                reportToShow.createdBy = {
                    id: reportToShow.createdBy,
                    name: this.getUserName(reportToShow.createdBy),
                }
                const path = `${this.companyId}/projects/${this.project.id}/timesheets`
                const storageRef = storage().ref(path)
                let attachments = []
                if (reportToShow.attachments) {
                    for (const file of reportToShow.attachments) {
                        const profilePicRef = storageRef.child(file)
                        await profilePicRef
                            .getDownloadURL()
                            .then(URL => {
                                attachments.push({ name: file, url: URL })
                            })
                            .catch(error => {
                                error && true
                            })
                    }
                }
                for (const attachment of attachments) {
                    const isPDF = attachment.name.includes('.pdf')
                    if (isPDF) {
                        const dataURL = await loadPDFPreview(attachment.url)
                        const file = dataURLtoFile(dataURL, attachment.name)
                        attachment.firstPageURL = URL.createObjectURL(file)
                        attachment.isPDF = isPDF
                    }
                }
                reportToShow.attachments = attachments
                await generateHTMLReport(reportToShow, this.project)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
