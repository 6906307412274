<template>
    <div>
        <v-data-table
            v-if="filter == 'timeline'"
            :headers="subHeaders"
            :items="formattedTimesheets"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3 py-0">
                        <h1 class="mr-4 my-0">USER REPORTS</h1>
                        <v-btn-toggle
                            mandatory
                            borderless
                            style="height: 36px;"
                            class="mr-4 my-0"
                        >
                            <v-btn
                                @click="filter = 'timeline'"
                                style="font-size: 12px; height: 36px;"
                                class="px-xs-1 px-sm-1 px-md-3 px-lg-3 px-xl-3 py-1"
                            >
                                TIMELINE
                            </v-btn>
                            <v-btn
                                @click="filter = 'groupByUser'"
                                style="font-size: 12px; height: 36px;"
                                class="px-xs-1 px-sm-1 px-md-3 px-lg-3 px-xl-3 py-1"
                            >
                                GROUP BY USER
                            </v-btn>
                        </v-btn-toggle>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            @click="navigateToTimesheets"
                            class="mr-4 mb-3"
                        >
                            <v-icon class="mx-2">
                                mdi-clipboard-text-clock-outline
                            </v-icon>
                            {{
                                $vuetify.breakpoint.smAndDown
                                    ? ''
                                    : 'MAKE A REPORT'
                            }}
                        </v-btn>
                        <v-btn
                            rounded
                            color="primary"
                            @click="getTimesheetsQR"
                            class="mr-4 mb-3"
                        >
                            <v-icon class="mx-2">
                                mdi-qrcode
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'SHOW QR' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.id`]="{ index }">
                <p class="my-0">
                    {{ index + 1 }}
                </p>
            </template>
            <template v-slot:[`item.projectLocation`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.projectLocation.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.scope`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.selectedCost.cost.reference }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.clockIn`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            item.clockIn
                                ? formatDate(item.clockIn._seconds)
                                : ''
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.clockOut`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            item.clockOut
                                ? formatDate(item.clockOut._seconds)
                                : ''
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.time`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            item.clockIn && item.clockOut
                                ? (
                                      (item.clockOut._seconds -
                                          item.clockIn._seconds) /
                                      3600
                                  ).toFixed(2) + ' hours'
                                : ''
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openMap(item)">
                        <v-icon>mdi-map-marker</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <v-data-table
            v-else
            :headers="headers"
            :items="itemsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            :height="$vuetify.breakpoint.mdAndDown ? '66vh' : '68vh'"
            fixed-header
            show-expand
            :expanded.sync="expanded"
            multiple-expand
            :item-class="itemRowBackground"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3 py-0">
                        <h1 class="mr-4 my-0">USER REPORTS</h1>
                        <v-btn-toggle
                            mandatory
                            borderless
                            style="height: 36px;"
                            class="mr-4 my-0"
                        >
                            <v-btn
                                @click="filter = 'timeline'"
                                style="font-size: 12px; height: 36px;"
                                class="px-xs-1 px-sm-1 px-md-3 px-lg-3 px-xl-3 py-1"
                            >
                                TIMELINE
                            </v-btn>
                            <v-btn
                                @click="filter = 'groupByUser'"
                                style="font-size: 12px; height: 36px;"
                                class="px-xs-1 px-sm-1 px-md-3 px-lg-3 px-xl-3 py-1"
                            >
                                GROUP BY USER
                            </v-btn>
                        </v-btn-toggle>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            @click="navigateToTimesheets"
                            class="mr-4 mb-3"
                        >
                            <v-icon class="mx-2">
                                mdi-clipboard-text-clock-outline
                            </v-icon>
                            {{
                                $vuetify.breakpoint.smAndDown
                                    ? ''
                                    : 'MAKE A REPORT'
                            }}
                        </v-btn>
                        <v-btn
                            rounded
                            color="primary"
                            @click="getTimesheetsQR"
                            class="mr-4 mb-3"
                        >
                            <v-icon class="mx-2">
                                mdi-qrcode
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'SHOW QR' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!-- HEADERS -->
            <template v-slot:[`header.user`]="{ header }">
                <v-text-field :label="header.text" v-model="userToFilter" />
            </template>
            <template v-slot:[`header.role`]="{ header }">
                <v-text-field :label="header.text" v-model="roleToFilter" />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.id`]="{ index }">
                <p class="my-0">
                    {{ index + 1 }}
                </p>
            </template>
            <template v-slot:[`item.hours`]="{ item }">
                <p class="my-0">
                    {{
                        (
                            item.formattedTimesheets.reduce(
                                (accum, report) =>
                                    accum +
                                    (report.clockIn && report.clockOut
                                        ? report.clockOut._seconds -
                                          report.clockIn._seconds
                                        : 0),
                                0
                            ) / 3600
                        ).toFixed(2)
                    }}
                </p>
            </template>
            <template v-slot:[`item.user`]="{ item }">
                <p class="my-0">
                    {{ item.name }}
                </p>
            </template>
            <template v-slot:[`item.role`]="{ item }">
                <p class="my-0">
                    {{ item.role }}
                </p>
            </template>
            <template
                v-slot:expanded-item="{
                    headers,
                    item: item,
                }"
            >
                <td
                    v-if="item.timesheet"
                    :colspan="headers.length"
                    class="pa-4 ma-0 outlined"
                    style="background: white !important"
                >
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-data-table
                                :headers="subHeaders"
                                :items="item.formattedTimesheets"
                                class="elevation-0"
                                :mobile-breakpoint="0"
                                :loading="loading"
                                disable-pagination
                                hide-default-footer
                            >
                                <template v-slot:headers="{ props }">
                                    <tr>
                                        <th
                                            v-for="header in props.headers"
                                            :key="header.text"
                                        >
                                            {{ header.text }}
                                        </th>
                                    </tr>
                                </template>
                                <!--ITEMS-->
                                <template v-slot:[`item.id`]="{ index }">
                                    <p class="my-0">
                                        {{ index + 1 }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.projectLocation`]="{ item }"
                                >
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{ item.projectLocation.name }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.scope`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{
                                                item.selectedCost.cost.reference
                                            }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.clockIn`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{
                                                item.clockIn
                                                    ? formatDate(
                                                          item.clockIn._seconds
                                                      )
                                                    : ''
                                            }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.clockOut`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{
                                                item.clockOut
                                                    ? formatDate(
                                                          item.clockOut._seconds
                                                      )
                                                    : ''
                                            }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.time`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{
                                                item.clockIn && item.clockOut
                                                    ? (
                                                          (item.clockOut
                                                              ._seconds -
                                                              item.clockIn
                                                                  ._seconds) /
                                                          3600
                                                      ).toFixed(2) + ' hours'
                                                    : ''
                                            }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <v-btn
                                            small
                                            icon
                                            @click="openMap(item)"
                                        >
                                            <v-icon>mdi-map-marker</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
        <!-- QR DIALOG -->
        <v-dialog v-model="qrDialog" persistent max-width="340px">
            <v-card>
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="qrDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Time Report QR
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider class="mx-5" />
                <v-card-text>
                    <vue-qr :text="link" :size="300"></vue-qr>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import vueQr from 'vue-qr'
import moment from 'moment'

export default {
    name: 'TimesheetReports',
    props: {
        project: {
            type: Object,
            Required: true,
            default: () => ({}),
        },
    },
    components: { vueQr },
    data: () => ({
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
            },
            {
                text: 'USER',
                value: 'user',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ROLE',
                value: 'role',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL HOURS',
                value: 'hours',
                align: 'center',
                sortable: false,
            },
        ],
        subHeaders: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT LOCATION',
                value: 'projectLocation',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SCOPE',
                value: 'scope',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLOCK IN',
                value: 'clockIn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLOCK OUT',
                value: 'clockOut',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL TIME',
                value: 'time',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        link: null,
        qrDialog: false,
        roleToFilter: undefined,
        userToFilter: undefined,
        externalUsers: [],
        filter: 'timeline',
        expanded: [],
    }),
    computed: {
        itemsFiltered() {
            let conditions = []
            if (this.userToFilter) {
                conditions.push(this.filterByUser)
            }
            if (this.roleToFilter) {
                conditions.push(this.filterByRole)
            }
            if (conditions.length > 0) {
                return this.externalUsers.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }
            return this.externalUsers
        },
        formattedTimesheets() {
            let items = []
            for (const user of this.externalUsers) {
                if (
                    user.formattedTimesheets &&
                    user.formattedTimesheets.length > 0
                ) {
                    items = [...items, ...user.formattedTimesheets]
                }
            }
            if (items.length > 0) {
                items.sort((a, b) => {
                    return a.date > b.date ? -1 : 1
                })
            }
            return items
        },
    },
    watch: {
        filter() {
            this.addUserName()
        },
    },
    async mounted() {
        try {
            this.loading = true
            await this.getData()
            this.addUserName()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        itemRowBackground(row) {
            const selectRow = this.expanded.find(item => item.id == row.id)
            return selectRow ? 'item_background' : ''
        },
        addUserName() {
            if (this.filter == 'timeline') {
                this.subHeaders.splice(1, 0, {
                    text: 'user',
                    value: 'username',
                    align: 'center',
                    sortable: false,
                })
            } else {
                if (this.subHeaders[1].value == 'username') {
                    this.subHeaders.splice(1, 1)
                }
            }
        },
        async getData() {
            try {
                this.externalUsers = await API.getExternalUsers({
                    projectId: this.project.id,
                })
                this.externalUsers.forEach(user => {
                    user.formattedTimesheets = []
                    if (user.timesheet) {
                        Object.keys(user.timesheet).forEach(date => {
                            user.timesheet[date].forEach(entry => {
                                user.formattedTimesheets.push({
                                    username: user.name,
                                    date,
                                    ...entry,
                                })
                            })
                        })
                    }
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async getTimesheetsQR() {
            try {
                this.loading = true
                if (!this.link) {
                    this.link = await API.getProjectTimesheetLink(
                        this.project.id
                    )
                    this.link = process.env.VUE_APP_CLIENT_HOST + this.link
                }
                this.qrDialog = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD hh:mm A')
        },
        async navigateToTimesheets() {
            try {
                this.loading = true
                if (!this.link) {
                    this.link = await API.getProjectTimesheetLink(
                        this.project.id
                    )
                }
                window.open(
                    process.env.VUE_APP_CLIENT_HOST + this.link,
                    '_blank'
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filterByUser(item) {
            return (
                item.name &&
                item.name
                    .toLowerCase()
                    .includes(this.userToFilter.toLowerCase())
            )
        },
        filterByRole(item) {
            return (
                item.role &&
                item.role
                    .toLowerCase()
                    .includes(this.roleToFilter.toLowerCase())
            )
        },
        openMap(item) {
            const lat = item.clockInLocation._latitude
            const long = item.clockInLocation._longitude
            if (lat && long) {
                const mapLink = `https://www.google.com/maps?q=${lat},${long}`
                window.open(mapLink, '_blank')
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.item_background {
    background-color: #eeeeee !important;
}
</style>
