<template>
    <div>
        <v-row>
            <v-col cols="12" sm="12" md="6" class="pr-1 pl-6">
                <v-col>
                    <v-row class="mt-1 mr-0">
                        <p class="text-h6 text-bold">Project Locations</p>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            class="mx-4"
                            @click="openCreateGeolocationForm"
                            :disabled="loading"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            NEW
                        </v-btn>
                    </v-row>
                    <v-text-field
                        v-model="geolocationToSearch"
                        hide-details
                        label="Search"
                        class="ml-n3"
                    />
                    <v-progress-linear
                        :active="loading"
                        indeterminate
                        color="primary"
                        class="mt-0 ml-n3"
                    ></v-progress-linear>
                    <v-col
                        class="d-flex align-center flex-wrap"
                        style="row-gap: 20px; max-height: 90vh; overflow-y: auto ; margin-top: 5px;"
                    >
                        <v-card
                            flat
                            class="mr-1"
                            :style="{
                                width: '100%',
                            }"
                            v-for="(location, index) in geolocationsFiltered"
                            :key="index"
                        >
                            <div
                                v-if="location"
                                class="d-flex"
                                @click="openEditGeolocationForm(location)"
                                style="cursor: pointer"
                            >
                                <div
                                    class="d-flex flex-column justify-space-around me-4"
                                >
                                    <v-row>
                                        <v-icon>mdi-map-marker</v-icon>
                                        <v-col
                                            ><span
                                                class="font-weight-bold text-singleline"
                                            >
                                                {{ location.name }}
                                            </span>
                                            <span
                                                class="text-caption text-singleline"
                                            >
                                                {{ location.notes || '' }}
                                            </span></v-col
                                        >
                                    </v-row>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-col>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pr-1 pl-6">
                <v-col>
                    <v-row class="mt-1 mr-0">
                        <p class="text-h6 text-bold">External Users</p>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            class="mx-4"
                            @click="openCreateExternalUserForm"
                            :disabled="loading"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            NEW
                        </v-btn>
                    </v-row>
                    <v-text-field
                        v-model="externalUserToSearch"
                        hide-details
                        label="Search"
                        class="ml-n3"
                    />
                    <v-progress-linear
                        :active="loading"
                        indeterminate
                        color="primary"
                        class="mt-0 ml-n3"
                    ></v-progress-linear>
                    <v-col
                        class="d-flex align-center flex-wrap"
                        style="row-gap: 20px; max-height: 34vh; overflow-y: auto ; margin-top: 5px;"
                    >
                        <v-card
                            flat
                            class="mr-1"
                            :style="{
                                width: '100%',
                                backgroundColor: !user.active
                                    ? '#fffdf2'
                                    : 'white',
                            }"
                            v-for="(user, index) in externalUsersFiltered"
                            :key="index"
                        >
                            <div
                                v-if="user"
                                class="d-flex"
                                @click="openEditExternalUserForm(user)"
                                style="cursor: pointer"
                            >
                                <v-avatar
                                    color="grey lighten-4"
                                    size="34"
                                    class="mr-1"
                                >
                                    <span class="text-h6">
                                        {{
                                            user.name
                                                .split(' ')
                                                .map((n, i, a) =>
                                                    i === 0 ||
                                                    i + 1 === a.length
                                                        ? n[0]
                                                        : null
                                                )
                                                .join('')
                                                .toUpperCase()
                                        }}
                                    </span>
                                </v-avatar>
                                <div
                                    class="d-flex flex-column justify-space-around me-4"
                                >
                                    <span
                                        class="font-weight-bold text-singleline"
                                    >
                                        {{ user.name }}
                                        {{ user.role ? `(${user.role})` : '' }}
                                    </span>
                                    <span class="text-caption text-singleline">
                                        {{ user.phone }}
                                    </span>
                                    <span class="text-caption text-singleline">
                                        {{ user.email }}
                                    </span>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-col>
            </v-col>
        </v-row>
        <!-- EXTERNAL USER FORM -->
        <v-dialog
            v-model="extenarlUserDialog"
            :retain-focus="false"
            persistent
            width="700"
        >
            <ExternalUserForm
                v-if="extenarlUserDialog"
                :projectId="project.id"
                :user="selectedExternalUser"
                :createForm="createForm"
                @closeDialog="closeExternalUserForm"
                @addExternalUser="addExternalUser"
                @replaceExternalUser="replaceExternalUser"
                @removeExternalUser="removeExternalUser"
            />
        </v-dialog>
        <!-- Geolocation Form -->
        <v-dialog
            v-model="geolocationDialog"
            :retain-focus="false"
            persistent
            width="700"
        >
            <GeolocationForm
                v-if="geolocationDialog"
                :projectId="project.id"
                :loc="selectedGeolocation"
                :createForm="createForm"
                @closeDialog="closeGeolocationForm"
                @addProjectLocation="addGeolocation"
                @replaceProjectLocation="replaceGeolocation"
                @removeProjectLocation="removeGeolocation"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'TimesheetSettings',
    props: {
        project: {
            type: Object,
            Required: true,
            default: () => ({}),
        },
    },
    components: {
        ExternalUserForm: () =>
            import('@/components/Timesheets/ExternalUserForm.vue'),
        GeolocationForm: () =>
            import('@/components/Timesheets/GeolocationForm.vue'),
    },
    data: () => ({
        loading: false,
        workLogs: [],
        sendEmailsDialog: false,
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
            },
            {
                text: 'USER',
                value: 'user',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ROLE',
                value: 'role',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        subHeaders: [
            {
                text: 'TYPE',
                value: 'type',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TRADE',
                value: 'trade',
                align: 'center',
                sortable: false,
                width: 300,
            },
            {
                text: 'HOURS',
                value: 'hours',
                align: 'center',
                sortable: false,
            },
            {
                text: 'FILES',
                value: 'files',
                align: 'center',
                sortable: false,
                width: 300,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        link: null,
        qrDialog: false,
        listener: undefined,
        items: [],
        roleToFilter: undefined,
        userToFilter: undefined,
        tradeToFilter: undefined,
        dateToFilter: undefined,
        reportDialog: false,
        menu: false,
        dates: [],
        dataOptions: {
            date: true,
            userName: true,
            hours: true,
            type: true,
            role: true,
            notes: '',
        },
        selectedExternalUser: {},
        createForm: false,
        extenarlUserDialog: false,
        externalUsers: [],
        externalUserToSearch: null,
        locations: [],
        geolocationToSearch: null,
        selectedGeolocation: {},
        geolocationDialog: false,
    }),
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        externalUsersFiltered() {
            let conditions = []

            if (this.externalUserToSearch) {
                conditions.push(this.filterUserByParams)
            }

            if (conditions.length > 0) {
                return this.externalUsers.filter(user => {
                    return conditions.every(condition => {
                        return condition(user)
                    })
                })
            }

            //this.sort()
            return this.externalUsers
        },
        geolocationsFiltered() {
            let conditions = []

            if (this.geolocationToSearch) {
                conditions.push(this.filterLocationByName)
            }

            if (conditions.length > 0) {
                return this.locations.filter(loc => {
                    return conditions.every(condition => {
                        return condition(loc)
                    })
                })
            }
            return this.locations
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.externalUsers = await API.getExternalUsers({
                projectId: this.project.id,
            })
            this.locations = await API.getProjectLocations({
                projectId: this.project.id,
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openCreateExternalUserForm() {
            this.createForm = true
            this.extenarlUserDialog = true
        },
        openEditExternalUserForm(externalUser) {
            this.selectedExternalUser = _.cloneDeep(externalUser)
            this.createForm = false
            this.extenarlUserDialog = true
        },
        closeExternalUserForm() {
            this.selectedExternalUser = {}
            this.extenarlUserDialog = false
            this.createForm = false
        },
        addExternalUser(externalUser) {
            this.externalUsers.push(externalUser)
        },
        replaceExternalUser(externalUser) {
            const index = this.externalUsers.findIndex(
                user => user.id == externalUser.id
            )
            if (index > -1) {
                this.externalUsers[index] = _.cloneDeep(externalUser)
            } else {
                this.externalUsers.push(externalUser)
            }
        },
        removeExternalUser(externalUser) {
            const index = this.externalUsers.findIndex(
                user => user.id == externalUser.id
            )
            if (index > -1) {
                this.externalUsers.splice(index, 1)
            }
        },
        filterUserByParams(item) {
            return (
                (item.name &&
                    item.name
                        .toLowerCase()
                        .includes(this.externalUserToSearch.toLowerCase())) ||
                (item.email &&
                    item.email
                        .toLowerCase()
                        .includes(this.externalUserToSearch.toLowerCase()))
            )
        },

        //project locations
        openCreateGeolocationForm() {
            this.createForm = true
            this.geolocationDialog = true
        },
        openEditGeolocationForm(location) {
            this.selectedGeolocation = _.cloneDeep(location)
            this.createForm = false
            this.geolocationDialog = true
        },
        closeGeolocationForm() {
            this.selectedGeolocation = {}
            this.geolocationDialog = false
            this.createForm = false
        },
        addGeolocation(location) {
            this.locations.push(location)
        },
        replaceGeolocation(location) {
            const index = this.locations.findIndex(loc => loc.id == location.id)
            if (index > -1) {
                this.locations[index] = _.cloneDeep(location)
            } else {
                this.locations.push(location)
            }
        },
        removeGeolocation(location) {
            const index = this.locations.findIndex(loc => loc.id == location.id)
            if (index > -1) {
                this.locations.splice(index, 1)
            }
        },
        filterLocationByName(item) {
            return (
                item.name &&
                item.name
                    .toLowerCase()
                    .includes(this.geolocationToSearch.toLowerCase())
            )
        },

        sort() {
            this.externalUsers.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
