<template>
    <div>
        <v-data-table
            :headers="filteredHeaders"
            :items="workOrdersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
            :height="$vuetify.breakpoint.mdAndDown ? '59vh' : '63vh'"
            multiple-expand
            :expanded.sync="expanded"
            show-expand
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-2 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3 pt-0">
                        <h1 class="mr-4 d-flex align-center">PROGRESS</h1>
                        <v-combobox
                            v-model="selectedProcess"
                            hide-details
                            label="Process"
                            :items="availableProcesses"
                            class="mb-2 ml-5"
                            style="z-index: 100; max-width: 200px;"
                        />

                        <v-spacer />

                        <div
                            class="d-flex my-0"
                            v-for="(insight, index) of insights"
                            :key="index"
                        >
                            <v-card
                                class="pt-3 px-1 ma-1"
                                min-width="220px"
                                max-width="220px"
                                height="65px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="
                                            getProgressColor(
                                                insight.key,
                                                weightedValue(insight.key)
                                            )
                                        "
                                        label
                                        :style="{ height: '40px' }"
                                    >
                                        <v-icon center>
                                            {{ insight.icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span class="text-h7">{{
                                                insight.title
                                            }}</span>
                                        </div>
                                        <p class="text-h7 black--text">
                                            {{ weightedValue(insight.key) }}
                                            {{
                                                insight.key != 'count'
                                                    ? '%'
                                                    : ''
                                            }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.process`]="{ header }">
                <v-row class="d-flex align center justify-center">
                    <p class="ma-0 pa-0 d-flex justify-center align-center">
                        {{ header.text }}
                    </p>
                </v-row>
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.projectManager`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.projectManager.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center" :class="['fixed-column']">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.progress`]="{ item }">
                <div class="d-flex justify-center">
                    <v-progress-linear
                        v-model="item.progress"
                        height="25"
                        :color="getProgressColor('progress', item.progress)"
                        rounded
                        class="no-interaction"
                    >
                        <strong>{{ item.progress.toFixed(1) }}%</strong>
                    </v-progress-linear>
                </div>
            </template>
            <template v-slot:[`item.releasedPercentage`]="{ item }">
                <div class="d-flex justify-center">
                    <v-progress-linear
                        v-model="item.releasedPercentage"
                        height="25"
                        :color="
                            getProgressColor('quality', item.releasedPercentage)
                        "
                        rounded
                        class="no-interaction"
                    >
                        <strong>{{
                            item.showReleased
                                ? `${item.releasedPercentage.toFixed(1)}%`
                                : 'NA'
                        }}</strong>
                    </v-progress-linear>
                </div>
            </template>
            <template v-slot:[`item.process`]="{ item, header }">
                <div class="d-flex justify-center align-center">
                    <p
                        :style="{ margin: 0 }"
                        v-if="item.progressData && item.progressData[header.id]"
                    >
                        {{ item.progressData[header.id].produced }}/{{
                            item.progressData[header.id].total
                        }}
                    </p>
                </div>
            </template>
            <template
                v-slot:expanded-item="{
                    item: item,
                }"
            >
                <td
                    :colspan="setSubheaders(item.items).length"
                    class="pa-4 ma-0 outlined"
                    style="z-index: -20"
                >
                    <v-row no-gutters>
                        <v-col cols="12" class="subtable">
                            <v-data-table
                                :headers="setSubheaders(item.items)"
                                :items="item.items"
                                class="elevation-0"
                                :mobile-breakpoint="0"
                                :loading="loading"
                                disable-pagination
                                hide-default-footer
                            >
                                <!--ITEMS-->
                                <template v-slot:[`item.qty`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <p class="my-0">
                                            {{ item.quantity }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:[`item.progress`]="{ item }">
                                    <div class="d-flex justify-center">
                                        <v-progress-linear
                                            v-model="item.progress"
                                            height="25"
                                            :color="
                                                getProgressColor(
                                                    'progress',
                                                    item.progress
                                                )
                                            "
                                            rounded
                                            class="no-interaction"
                                        >
                                            <strong
                                                >{{
                                                    item.progress.toFixed(1)
                                                }}%</strong
                                            >
                                        </v-progress-linear>
                                    </div>
                                </template>
                                <template
                                    v-slot:[`item.releasedPercentage`]="{
                                        item,
                                    }"
                                >
                                    <div class="d-flex justify-center">
                                        <v-progress-linear
                                            v-model="item.releasedPercentage"
                                            height="25"
                                            :color="
                                                getProgressColor(
                                                    'quality',
                                                    item.releasedPercentage || 0
                                                )
                                            "
                                            rounded
                                            class="no-interaction"
                                        >
                                            <strong>{{
                                                item.showReleased
                                                    ? `${item.releasedPercentage.toFixed(
                                                          1
                                                      )}%`
                                                    : 'NA'
                                            }}</strong>
                                        </v-progress-linear>
                                    </div>
                                </template>
                                <template
                                    v-slot:[`item.process`]="{ item, header }"
                                >
                                    <div
                                        class="d-flex justify-center align-center"
                                        style="z-index: -2;"
                                    >
                                        <p :style="{ margin: 0 }">
                                            {{ item.progressData[header.id] }}
                                        </p>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </td>
            </template>
            <!-- FOOTER -->
            <template v-slot:[`body.append`]>
                <tr
                    :style="{
                        'background-color': '#eeeeee',
                        position: 'sticky',
                        bottom: '0',
                        'z-index': '10',
                    }"
                >
                    <td :colspan="projectId ? 5 : 7"></td>
                    <td
                        v-for="(process, index) in processesInTable"
                        :key="index"
                        class="text-center"
                    >
                        {{
                            `${processDetails(process.id).produced}/${
                                processDetails(process.id).total
                            }`
                        }}
                        <br />
                        {{
                            `${processDetails(process.id).progress.toFixed(
                                2
                            )} %`
                        }}
                        <br />
                        {{ `${processDetails(process.id).workOrdersCount} WO` }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'Deliveries',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    components: {},
    data: () => ({
        projectToFilter: undefined,
        loading: false,
        workOrders: [],
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 120,
            },
            {
                text: 'PROJECT MANAGER',
                value: 'projectManager',
                align: 'center',
                sortable: false,
                width: 220,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
                width: 240,
            },
            {
                text: 'QTY',
                value: 'woQty',
                align: 'center',
                sortable: false,
                width: 120,
            },
            {
                text: 'PROGRESS',
                value: 'progress',
                align: 'center',
                sortable: true,
                width: 120,
            },
            {
                text: 'RELEASED',
                value: 'releasedPercentage',
                align: 'center',
                sortable: true,
                width: 120,
                divider: true,
            },
        ],
        subheaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
                width: 200,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
                width: 220,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'PROGRESS',
                value: 'progress',
                align: 'center',
                sortable: true,
                divider: true,
                width: 120,
            },
            {
                text: 'RELEASED',
                value: 'releasedPercentage',
                align: 'center',
                sortable: true,
                width: 120,
                divider: true,
            },
        ],
        processHeaders: [],
        company: JSON.parse(localStorage.getItem('company')),
        listener: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        settingProcesses: undefined,
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        qualityProcess: {
            id: 'quality',
            name: 'quality',
        },
        structuredWorkOrders: [],
        expanded: [],
        insights: [
            {
                key: 'count',
                color: 'primary',
                iconColor: '#1A5276',
                icon: 'mdi-factory',
                title: 'Work Orders',
            },
            {
                key: 'production',
                color: '#19405c',
                iconColor: '#1A5276',
                icon: 'mdi-tools',
                title: 'Production Progress',
            },
            {
                key: 'quality',
                color: '#0b1c28',
                iconColor: '#1A5276',
                icon: 'mdi-check',
                title: 'Quality Progress',
            },
        ],
        availableProcesses: [],
        processesInTable: [],
        selectedProcess: null,
        processData: {},
        height: 0,
    }),
    computed: {
        workOrdersFiltered() {
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (this.selectedProcess) {
                conditions.push(this.filterByProcess)
            }
            if (conditions.length > 0) {
                return this.structuredWorkOrders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            this.sort()
            return this.structuredWorkOrders
        },
        filteredHeaders() {
            let headers = []
            let addPacking = false
            headers.push(...this.headers)
            this.workOrdersFiltered.forEach(w => {
                if (w.items) {
                    w.items.forEach(i => {
                        if (i.progressData) {
                            Object.keys(i.progressData).forEach(processId => {
                                if (processId == this.packingProcess.id) {
                                    addPacking = true
                                } else if (
                                    !headers.find(h => h.id == processId)
                                ) {
                                    const processHeader = this.processHeaders.find(
                                        header => header.id == processId
                                    )
                                    if (processHeader) {
                                        headers.push(processHeader)
                                    }
                                }
                            })
                        }
                    })
                }
            })
            if (addPacking) {
                headers.push({
                    text: this.packingProcess.name.toUpperCase(),
                    value: 'process',
                    id: this.packingProcess.id,
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: 110,
                })
            }
            this.getAvailableProcesses(headers)
            return headers
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.onResize()
            //remove project column in project details view
            if (this.projectId) {
                this.headers.splice(1, 2)
            }
            //add processes columns
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            this.settingProcesses.processes.forEach(process => {
                this.processHeaders.push({
                    text: process.name.toUpperCase(),
                    value: 'process',
                    id: process.id,
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: 120,
                })
            })

            const {
                data: { users },
            } = await API.getLiteUsers()

            //load data if is general view
            let projects = []
            if (
                !this.projectId ||
                !this.project ||
                Object.keys(this.project).length == 0
            ) {
                projects = await API.getLiteProjects({ all: true })
            } else {
                projects = [this.project]
            }

            let query = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('workOrders')
                .where('status', 'in', ['approved', 'finished'])

            if (this.projectId) {
                query = query.where('projectId', '==', this.projectId)
            }

            this.listener = await query.onSnapshot(async docSnapshot => {
                docSnapshot.docChanges().forEach(async change => {
                    const workOrder = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    const userIndex = users.findIndex(
                        u => u.id == workOrder.projectManager
                    )
                    if (userIndex >= 0) {
                        workOrder.projectManager = users[userIndex]
                    } else {
                        workOrder.projectManager = {
                            id: workOrder.projectManager,
                        }
                        workOrder.projectManager.name = ''
                    }
                    if (change.type === 'added') {
                        const projectIndex = projects.findIndex(
                            p => p.id == workOrder.projectId
                        )
                        if (projectIndex >= 0) {
                            workOrder.project = projects[projectIndex]
                        } else {
                            workOrder.project = await API.getProject(
                                workOrder.projectId
                            )
                            projects.push(workOrder.project)
                        }
                        this.workOrders.splice(0, 0, workOrder)
                    }
                    if (change.type === 'modified') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            const projectIndex = projects.findIndex(
                                p => p.id == workOrder.projectId
                            )
                            if (projectIndex >= 0) {
                                workOrder.project = projects[projectIndex]
                            } else {
                                workOrder.project = await API.getProject(
                                    workOrder.projectId
                                )
                                projects.push(workOrder.project)
                            }
                            this.workOrders.splice(index, 1, workOrder)
                        }
                    }
                    if (change.type === 'removed') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            this.workOrders.splice(index, 1)
                        }
                    }
                    this.workOrders.forEach(wo => {
                        this.formatItems(wo)
                    })
                })
                this.structureData()
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            this.height = window.innerHeight + 1000
        },
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        structureData() {
            let structuredWorkOrders = _.cloneDeep(this.workOrders)
            structuredWorkOrders.forEach(workOrder => {
                if (workOrder.items) {
                    workOrder.items.forEach(item => {
                        item.progressData = {}
                        if (item.processes) {
                            item.processes.forEach(processId => {
                                const process =
                                    processId == this.readyForPackingProcess.id
                                        ? this.packingProcess.id
                                        : processId
                                if (processId != this.qualityProcess.id) {
                                    item.progressData[process] = 0
                                }
                            })
                        }
                        if (item.production) {
                            item.production.forEach(entry => {
                                if (
                                    entry.process.id ==
                                    this.readyForPackingProcess.id
                                ) {
                                    entry.process.id == this.packingProcess.id
                                }

                                const entryProcess =
                                    entry.process.id ==
                                    this.readyForPackingProcess.id
                                        ? this.packingProcess.id
                                        : entry.process.id

                                if (entryProcess != this.packingProcess.id) {
                                    item.progressData[entryProcess] =
                                        item.progressData[entryProcess] || 0
                                    item.progressData[entryProcess] += Number(
                                        entry.qty
                                    )
                                }
                            })
                        }
                        if (item.packedQty) {
                            item.progressData[this.packingProcess.id] = Number(
                                item.packedQty
                            )
                        }
                        item.progress = 0
                        Object.values(item.progressData).forEach(qty => {
                            item.progress += Number(qty)
                        })
                        item.progress =
                            (item.progress /
                                (item.quantity *
                                    Object.values(item.progressData).length)) *
                            100
                        item.released = 0
                        if (item.processes.includes(this.qualityProcess.id)) {
                            if (item.quality) {
                                item.quality.forEach(entry => {
                                    item.released += Number(
                                        entry.releaseQty || 0
                                    )
                                })
                            }
                            item.showReleased = true
                        } else if (workOrder.assemblyWorkOrder == undefined) {
                            if (item.quality) {
                                item.quality.forEach(entry => {
                                    item.released += Number(
                                        entry.releaseQty || 0
                                    )
                                })
                            }
                            item.showReleased = true
                        }
                        item.releasedPercentage =
                            (item.released / item.quantity) * 100
                    })
                }
                workOrder.progress = this.getTotalPercentage(workOrder)
                workOrder.woQty = this.getTotalWOQty(workOrder)
                workOrder.progressData = this.getTotalProgressData(workOrder)
                workOrder.releasedPercentage = this.getTotalReleased(workOrder)
                workOrder.totalQualityItems = this.getTotalQualityItems(
                    workOrder
                )
            })

            this.structuredWorkOrders = structuredWorkOrders
        },
        getTotalWOQty(wo) {
            if (wo.items) {
                return wo.items.reduce(
                    (accum, item) => accum + item.quantity,
                    0
                )
            } else {
                return 0
            }
        },
        getTotalPercentage(wo) {
            if (wo.items) {
                let produced = 0
                let total = 0
                wo.items.forEach(item => {
                    Object.values(item.progressData).forEach(qty => {
                        produced += Number(qty)
                    })
                    total += Number(
                        item.quantity * Object.values(item.progressData).length
                    )
                })
                if (total > 0) {
                    return (produced / total) * 100
                } else {
                    return 0
                }
            } else {
                return 0
            }
        },
        getTotalProgressData(workOrder) {
            let progressData = {}
            if (workOrder.items) {
                workOrder.items.forEach(item => {
                    if (item.progressData) {
                        Object.keys(item.progressData).forEach(processId => {
                            progressData[processId] = progressData[
                                processId
                            ] || { produced: 0, total: 0 }
                            progressData[processId].produced += Number(
                                item.progressData[processId]
                            )
                            progressData[processId].total += Number(
                                item.quantity
                            )
                        })
                    }
                })
            }
            return progressData
        },
        getTotalReleased(wo) {
            if (wo.items) {
                let released = 0
                let total = 0
                wo.items.forEach(item => {
                    if (item.showReleased) {
                        wo.showReleased = true
                        total += Number(item.quantity || 0)
                        released += Number(item.released || 0)
                    }
                })
                if (total > 0) {
                    return (released / total) * 100
                } else {
                    return 0
                }
            } else {
                return 0
            }
        },
        getTotalQualityItems(wo) {
            let total = 0
            if (wo.items) {
                wo.items.forEach(item => {
                    if (item.showReleased) {
                        total += Number(item.quantity || 0)
                    }
                })
            }
            return total
        },
        sort() {
            this.structuredWorkOrders.sort(
                (a, b) =>
                    this.getTotalPercentage(b) - this.getTotalPercentage(a)
            )
        },

        formatItems(workOrder) {
            if (
                !(
                    (workOrder.status == 'closed' ||
                        workOrder.status == 'cancelled') &&
                    !workOrder.partNumbers
                )
            ) {
                delete workOrder.items
                workOrder.items = []
            }
            if (workOrder.partNumbers) {
                Object.keys(workOrder.partNumbers).forEach(partNumberId => {
                    let item = {
                        partNumberId,
                    }
                    Object.keys(workOrder.partNumbers[partNumberId]).forEach(
                        key => {
                            item[key] = workOrder.partNumbers[partNumberId][key]
                        }
                    )
                    workOrder.items.push(item)
                })
            }
            return workOrder
        },
        getProgressColor(key, percentage) {
            if (key == 'count') return 'primary'
            if (percentage < 25) return 'error'
            if (percentage < 50) return 'orange'
            if (percentage < 75) return 'warning'
            if (percentage < 100) return 'info'
            return 'success'
        },
        weightedValue(key) {
            let totalPercentage = 0
            let totalQtys = 0
            if (key == 'production') {
                this.workOrdersFiltered.forEach(workOrder => {
                    totalPercentage +=
                        Number(workOrder.progress || 0) *
                        Number(workOrder.woQty || 0)
                    totalQtys += Number(workOrder.woQty || 0)
                })
            } else if (key == 'quality') {
                this.workOrdersFiltered.forEach(workOrder => {
                    totalPercentage +=
                        Number(workOrder.releasedPercentage || 0) *
                        Number(workOrder.totalQualityItems || 0)
                    totalQtys += Number(workOrder.totalQualityItems || 0)
                })
            } else if (key == 'count') {
                return this.workOrdersFiltered.length
            }

            return (totalQtys > 0 ? totalPercentage / totalQtys : 100).toFixed(
                1
            )
        },
        setSubheaders(items) {
            let headers = []
            let addPacking = false
            headers.push(...this.subheaders)
            items.forEach(i => {
                if (i.progressData) {
                    Object.keys(i.progressData).forEach(processId => {
                        if (processId == this.packingProcess.id) {
                            addPacking = true
                        } else if (!headers.find(h => h.id == processId)) {
                            const processHeader = this.processHeaders.find(
                                header => header.id == processId
                            )
                            if (processHeader) {
                                headers.push(processHeader)
                            }
                        }
                    })
                }
            })
            if (addPacking) {
                headers.push({
                    text: this.packingProcess.name.toUpperCase(),
                    value: 'process',
                    id: this.packingProcess.id,
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: 120,
                })
            }
            return headers
        },
        getAvailableProcesses(headers) {
            const processes = headers
                .filter(h => h.value === 'process')
                .map(p => ({
                    text:
                        p.text.charAt(0).toUpperCase() +
                        p.text.slice(1).toLowerCase(),
                    id: p.id,
                }))
            this.processesInTable = processes
            if (this.availableProcesses.length == 0) {
                this.availableProcesses = processes
            }
        },
        filterByProcess(order) {
            if (order.progressData) {
                if (
                    order.progressData[this.selectedProcess.id] &&
                    (order.progressData[this.selectedProcess.id].produced ||
                        order.progressData[this.selectedProcess.id].total)
                ) {
                    return true
                } else return false
            } else return false
        },
        processDetails(processId) {
            let workOrdersCount = 0
            let produced = 0
            let total = 0
            this.workOrdersFiltered.forEach(workOrder => {
                if (workOrder.progressData[processId]) {
                    workOrdersCount++
                    produced += +workOrder.progressData[processId].produced || 0
                    total += +workOrder.progressData[processId].total || 0
                }
            })
            const progress = total > 0 ? (produced / total) * 100 : 100
            return { workOrdersCount, produced, total, progress }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px;
}
.no-interaction {
    pointer-events: none;
}
.subtable {
    z-index: 1;
    background-color: #eeeeee;
}
</style>
