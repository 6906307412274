const moment = require('moment')
const html2pdf = require('html2pdf.js')
const script = document.createElement('script')
script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js'
script.onload = () => {
    window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js'
}
document.head.appendChild(script)

export const generateHTMLReport = async (report, project, open = true) => {
    let weatherData = []
    if (report.weather != undefined) {
        const weatherHours =
            report.weather.days.find(day => day.hours?.length)?.hours || null
        if (weatherHours) {
            //6:00 AM
            weatherData.push({
                conditions: weatherHours[6].conditions,
                temp: weatherHours[6].temp,
                precip: weatherHours[6].precip,
                humidity: weatherHours[6].humidity,
                windspeed: weatherHours[6].windspeed,
                time: weatherHours[6].datetimeEpoch,
            })
            //12:00 PM
            weatherData.push({
                conditions: weatherHours[12].conditions,
                temp: weatherHours[12].temp,
                precip: weatherHours[12].precip,
                humidity: weatherHours[12].humidity,
                windspeed: weatherHours[12].windspeed,
                time: weatherHours[12].datetimeEpoch,
            })
            //4:00 PM
            weatherData.push({
                conditions: weatherHours[16].conditions,
                temp: weatherHours[16].temp,
                precip: weatherHours[16].precip,
                humidity: weatherHours[16].humidity,
                windspeed: weatherHours[16].windspeed,
                time: weatherHours[16].datetimeEpoch,
            })
        }
    }
    const htmlContent = `
        <!DOCTYPE html>
                            <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <style>
                                    body {
                                        font-family: Arial, sans-serif;
                                        margin: 0;
                                        padding: 0;
                                    }
                                    .container {
                                        width: 100%;
                                        max-width: 900px;
                                        margin: auto;
                                        padding: 5px 20px;
                                    }
                                    h2 {
                                        color: #333;
                                        font-size: 18px;
                                        margin-bottom: 10px;
                                    }
                                    .divider {
                                        border-top: 1px solid #ddd;
                                        margin: 10px 0;
                                    }
                                    .row {
                                        display: flex;
                                        flex-wrap: wrap;
                                        margin-bottom: 20px;
                                    }
                                    .col {
                                        padding: 10px;
                                    }
                                    .checkbox-label {
                                        display: flex;
                                        align-items: center;
                                    }
                                    table {
                                        width: 100%;
                                        page-break-inside: auto;
                                        border-collapse: collapse;
                                    }
                                    th, td {
                                        border-bottom: 1px solid rgb(201, 201, 201);
                                        padding: 8px;
                                        text-align: left;
                                    }
                                    .attachment{
                                        font-size: 12px;
                                        border-radius: 20px;
                                        border: none;
                                        padding: 5px 10px;
                                        background-color: transparent;
                                        color: #1f6bb5;
                                        font-weight: bold;
                                        transition: transform 0.1s ease;
                                    }
                                    .attachment:hover{
                                        cursor: pointer;
                                    }
                                    .attachment:active {
                                        transform: scale(0.95);
                                        background-color: #1f6bb5;
                                    }
                                    .avoid-break {
                                        page-break-inside: avoid;
                                        break-inside: avoid;
                                    }
                                    .page-break-before {
                                        page-break-before: always;
                                        break-before: always;
                                    }
                                    .page-break-after {
                                        page-break-after: always;
                                        break-after: always;
                                    }
                                </style>
                            </head>
                            <body style="margin: 20px 0px;">
                                <div class="container" >
                                    <div class="row">
                                        <div class="col" style="flex: 2; padding-bottom: 0px;">
                                            <h5 style="font-weight: 100; margin-bottom: -10px; margin-top: 0px;">${report.type[0].toUpperCase() +
                                                report.type.slice(
                                                    1
                                                )} Report</h5>
                                            <h2 style="margin-top: 15px; margin-bottom: 0px;">${
                                                report.date
                                            }</h2>
                                        </div>
                                        <div class="col" style="flex: 2;text-align: right;padding-bottom: 0px;">
                                             ${
                                                 open
                                                     ? '<button onclick="download()" class="attachment">Download</button>'
                                                     : ''
                                             } 
                                             <h2>
                                             ${project.name}
                                             </h2>
                                        </div>
                                    </div>
                                    
                                    <div class="divider"></div>
                                </div>
                                ${
                                    report.dataOptions.weather &&
                                    weatherData.length > 0
                                        ? `<div class="container">

                                        <h2>WEATHER <span style="font-weight: 100; font-size: 15px; margin-left: 5px;"> (${
                                            report.projectLocation.name
                                        })</span></h2>
                                        <div class="divider"></div>

                                        <table>
                                            
                                            <tr>
                                                <th>Time</th>
                                                <th>Condition</th>
                                                <th style="text-align: center;">Temperature</th>
                                                <th style="text-align: center;">Precipitation</th>
                                                <th style="text-align: center;">Humidity</th>
                                                <th style="text-align: center;">Wind</th>
                                            </tr>
                                            ${generateWeatherRows(weatherData)}
                                        </table>
                                    </div>`
                                        : ''
                                }
                                ${
                                    report.dataOptions.generalInfo
                                        ? `<div class="container" style="margin-top: 20px;">
                                    <h2>GENERAL INFO</h2>
                                    <div class="divider"></div>

                                ${
                                    report.scheduleDelays
                                        ? `<div class="row">
                                        <div class="col" style="flex: 1;">
                                            <label class="checkbox-label">
                                                Schedule Delays
                                            </label>
                                        </div>
                                        <div class="col" style="flex: 3;">
                                            <label>${report.scheduleDelaysText ||
                                                '-'}</label>
                                        </div>
                                    </div>`
                                        : ''
                                }
                                    

                                ${
                                    report.weatherImpact
                                        ? `<div class="row">
                                        <div class="col" style="flex: 1;">
                                            <label class="checkbox-label">Weather Impact
                                            </label>
                                        </div>
                                        <div class="col" style="flex: 3;">
                                            <label>${report.weatherImpactText ||
                                                '-'}</label>
                                        </div>
                                    </div>`
                                        : ''
                                }
                                    

                                    ${
                                        report.accidents
                                            ? `<div class="row">
                                        <div class="col" style="flex: 1;">
                                            <label class="checkbox-label">Accidents On Site
                                            </label>
                                        </div>
                                        <div class="col" style="flex: 3;">
                                            <label>${report.accidentsText ||
                                                '-'}</label>
                                        </div>
                                    </div>`
                                            : ''
                                    }
                                    

                                    ${
                                        report.injuries
                                            ? `<div class="row">
                                        <div class="col" style="flex: 1;">
                                            <label class="checkbox-label">Injuries Reported
                                            </label>
                                        </div>
                                        <div class="col" style="flex: 3;">
                                            <label>${report.injuriesText ||
                                                '-'}</label>
                                        </div>
                                    </div>`
                                            : ''
                                    }
                                    

                                    <div class="row">
                                        <div class="col" style="flex: 1;">
                                            <label class="checkbox-label" >General Notes
                                            </label>
                                        </div>
                                        <div class="col" style="flex: 3;">
                                            <label rows="3" > ${report.generalNotes ||
                                                '-'}</label>
                                        </div>
                                    </div>
                                </div>`
                                        : ''
                                }
                                ${
                                    report.dataOptions.workLog &&
                                    report.workLog &&
                                    report.workLog.length > 0
                                        ? `<div class="container">
                                    <h2>WORK LOG</h2>
                                    <div class="divider"></div>

                                    <table>
                                        ${generateWorkLogTable(
                                            report.workLog || [],
                                            report.dataOptions || {}
                                        )}
                                    </table>
                                </div>`
                                        : ''
                                }
                                ${
                                    report.dataOptions.equipmentLog &&
                                    report.equipmentEntries &&
                                    report.equipmentEntries.length > 0
                                        ? `<div class="container" style="margin-top: 20px;">
                                    <h2>EQUIPMENT LOG</h2>
                                    <div class="divider"></div>

                                    <table>
                                        
                                        <tr>
                                            <th>Description</th>
                                            <th style="text-align: center;">Hours Used</th>
                                            <th style="text-align: center;">Time Delivered</th>
                                            <th style="text-align: center;">Time Removed</th>
                                            <th>Notes</th>
                                        </tr>
                                        ${generateEquipmentLogRows(
                                            report.equipmentEntries || []
                                        )}
                                    </table>
                                </div>`
                                        : ''
                                }
                                
                                ${
                                    report.dataOptions.materialDelivery &&
                                    report.materialEntries &&
                                    report.materialEntries.length > 0
                                        ? `<div class="container" style="margin-top: 20px;">
                                    <h2>MATERIAL DELIVERY</h2>
                                    <div class="divider"></div>

                                    <table>
                                        
                                        <tr>
                                        ${
                                            report.materialDeliveryOptions
                                                ? report.materialDeliveryOptions
                                                      .description
                                                    ? `<th>Description</th>`
                                                    : ''
                                                : `<th>Description</th>`
                                        }
                                        ${
                                            report.materialDeliveryOptions
                                                ? report.materialDeliveryOptions
                                                      .quantity
                                                    ? `<th style="text-align: center;">Quantity</th>`
                                                    : ''
                                                : `<th style="text-align: center;">Quantity</th>`
                                        }
                                        ${
                                            report.materialDeliveryOptions
                                                ? report.materialDeliveryOptions
                                                      .unit
                                                    ? `<th style="text-align: center;">Units</th>`
                                                    : ''
                                                : `<th style="text-align: center;">Units</th>`
                                        }
                                        ${
                                            report.materialDeliveryOptions
                                                ? report.materialDeliveryOptions
                                                      .unitCost
                                                    ? `<th style="text-align: center;">Unit Cost</th>`
                                                    : ''
                                                : `<th style="text-align: center;">Unit Cost</th>`
                                        }
                                        ${
                                            report.materialDeliveryOptions
                                                ? report.materialDeliveryOptions
                                                      .totalCost
                                                    ? `<th style="text-align: center;">Total Cost</th>`
                                                    : ''
                                                : `<th style="text-align: center;">Total Cost</th>`
                                        }
                                        ${
                                            report.materialDeliveryOptions
                                                ? report.materialDeliveryOptions
                                                      .notes
                                                    ? `<th>Notes</th>`
                                                    : ''
                                                : `<th>Notes</th>`
                                        }
                                        </tr>
                                        ${generateMaterialDeliveryRows(
                                            project.currency,
                                            report.materialEntries || [],
                                            report
                                        )}
                                    </table>
                                </div>`
                                        : ''
                                }
                                
                                ${
                                    report.dataOptions.activityLog &&
                                    report.activityEntries &&
                                    report.activityEntries.length > 0
                                        ? `<div class="container" style="margin-top: 20px;">
                                    <h2>ACTIVITY LOG</h2>
                                    <div class="divider"></div>

                                    <table>
                                        <tr>
                                            <th>Activity</th>
                                            <th style="text-align: center;">Percent Complete</th>
                                            <th>Notes</th>
                                        </tr>
                                        ${generateActivityLogRows(
                                            report.activityEntries || []
                                        )}
                                        
                                    </table>
                                </div>`
                                        : ''
                                }
                                
                                ${
                                    report.attachments &&
                                    report.attachments.length > 0
                                        ? `<div class="container avoid-break" style="margin-top: 20px;">
                                    <h2>ATTACHMENTS</h2>
                                    <div class="divider"></div>
                                    <div style="display: flex; flex-wrap: wrap; row-gap: 10px; column-gap: 15px;">
                                      ${generateAttachemntItems(
                                          report.attachments
                                      )}
                                    </div>
                                </div>`
                                        : ''
                                }
                                
                                <div class="container  avoid-break" style="margin-top: 20px;">
                                    <h2>Created By</h2>
                                            <div class="divider"></div>
                                            <label class="checkbox-label" >
                                                ${report.createdBy.name || ''}
                                            </label>
                                </div>
                                <script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js"></script>
                                <script>
                                    async function download() {
                                        const setHTML = \`${htmlDownload.replace(
                                            /`/g,
                                            '\\`'
                                        )}\`;
                                        const tempDoc = new DOMParser().parseFromString(setHTML, "text/html");
                                        tempDoc.body.innerHTML += document.body.innerHTML
                                        tempDoc.querySelectorAll("button").forEach(button => button.remove());
                                        tempDoc.querySelectorAll("script").forEach(script => script.remove());
                                        const container = tempDoc.createElement('div')
                                        container.innerHTML = tempDoc.documentElement.outerHTML

                                        const options = {
                                            margin: 10,
                                            filename: '${report.type[0].toUpperCase() +
                                                report.type.slice(1) +
                                                ' Report' +
                                                ' ' +
                                                report.date}.pdf',
                                            image: { type: 'jpeg', quality: 0.98 },
                                            html2canvas: { scale: 2, useCORS: true },
                                            jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' }
                                        };
                                        container.style.width = "185mm";
                                        await html2pdf().from(container).set(options).save();
                                        
                                    }
                                </script>
                            </body>
                            </html>
    `
    if (!open) {
        const container = document.createElement('div')
        container.innerHTML = htmlContent
        document.body.appendChild(container)

        const options = {
            margin: 10,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' },
        }

        const res = await html2pdf()
            .from(container)
            .set(options)
            .outputPdf('blob')

        document.body.removeChild(container)
        return res
    } else {
        const newWindow = window.open('', '_blank')
        newWindow.document.write(htmlContent)
        newWindow.document.title = `${report.date} Field Report - ${project.name}`
        newWindow.document.close()
    }
}

const htmlDownload = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
        }
        .container {
            width: 100%;
            max-width: 900px;
            margin: auto;
            padding: 5px 20px;
        }
        h2 {
            color: #333;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .divider {
            border-top: 1px solid #ddd;
            margin: 10px 0;
        }
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }
        .col {
            padding: 10px;
        }
        .checkbox-label {
            display: flex;
            align-items: center;
        }
        table {
            width: 100%;
            page-break-inside: auto;
            border-collapse: collapse;
        }
        th, td {
            border-bottom: 1px solid rgb(201, 201, 201);
            padding: 8px;
            text-align: left;
        }
        .attachment{
            font-size: 12px;
            border-radius: 20px;
            border: none;
            padding: 5px 10px;
            background-color: transparent;
            color: #1f6bb5;
            font-weight: bold;
            transition: transform 0.1s ease;
        }
        .attachment:hover{
            cursor: pointer;
        }
        .attachment:active {
            transform: scale(0.95);
            background-color: #1f6bb5;
        }
        .avoid-break {
            page-break-inside: avoid;
            break-inside: avoid;
        }
        .page-break-before {
            page-break-before: always;
            break-before: always;
        }
        .page-break-after {
            page-break-after: always;
            break-after: always;
        }
    </style>
</head>
<body style="margin: 20px 0px;">
</body>
</html>
`

const generateWeatherRows = data => {
    return data
        .map(
            item => `
        <tr>
            <td style="text-align: left">${formatDate2(item.time)}</td>
            <td style="text-align: left;">${item.conditions}</td>
            <td style="text-align: center;">${item.temp} °C</td>
            <td style="text-align: center;">${item.precip.toFixed(1)} mm</td>
            <td style="text-align: center;">${item.humidity.toFixed(2)} %</td>
            <td style="text-align: center;">${item.windspeed.toFixed(
                1
            )} Km/h</td>
        </tr>`
        )
        .join('')
}

const formatDate2 = seconds => {
    return moment.unix(seconds).format('hh:mm A')
}

const generateWorkLogTable = (data, dataOptions) => {
    let headers = ''
    let rows = ''

    const perUserHeaders = [
        { key: 'id', label: 'Id' },
        { key: 'userName', label: 'User Name' },
        { key: 'role', label: 'Role' },
        { key: 'hours', label: 'Hours' },
        { key: 'notes', label: 'Notes' },
        { key: 'location', label: 'Location' },
    ]

    const perTradeHeaders = [
        { key: 'trade', label: 'Trade' },
        { key: 'usersQty', label: 'Workers Qty' },
        { key: 'accumulatedHours', label: 'Total Hours' },
        { key: 'accumulatedNotes', label: 'Notes' },
    ]

    const selectedHeaders = dataOptions.perUser
        ? perUserHeaders
        : perTradeHeaders

    // filtered Headers
    const filteredHeaders = selectedHeaders.filter(
        header => dataOptions[header.key]
    )

    //rowsText
    if (dataOptions.perUser) {
        data.forEach((entry, index) => {
            rows += '<tr>'
            filteredHeaders.forEach(header => {
                if (header.key == 'id') {
                    rows += `<td style="text-align: center">${index + 1}</td>`
                } else if (header.key == 'userName') {
                    rows += `<td style="text-align: center">${entry.name}</td>`
                } else if (header.key == 'role') {
                    rows += `<td style="text-align: center">${entry.role}</td>`
                } else if (header.key == 'hours') {
                    rows += `<td style="text-align: center">${entry.time.toFixed(
                        2
                    )}</td>`
                } else if (header.key == 'notes') {
                    rows += `<td style="text-align: center">${entry.notes ||
                        '-'}</td>`
                } else if (header.key == 'location') {
                    rows += `<td style="text-align: center">${entry.projectLocation.name}</td>`
                }
            })
            rows += '</tr>'
        })
    } else {
        rows += '<tr>'
        filteredHeaders.forEach(header => {
            if (header.key == 'usersQty') {
                rows += `<td style="text-align: center">${data[0].qty}</td>`
            } else if (header.key == 'trade') {
                rows += `<td style="text-align: center">${data[0].trade}</td>`
            } else if (header.key == 'accumulatedHours') {
                rows += `<td style="text-align: center">${data[0].time.toFixed(
                    2
                )}</td>`
            } else if (header.key == 'accumulatedNotes') {
                rows += `<td style="text-align: center">${data[0].workLogNotes}</td>`
            }
        })
        rows += '</tr>'
    }
    //headersText
    headers = filteredHeaders
        .map(header => `<th style="text-align: center">${header.label}</th>`)
        .join('')

    return headers + rows
}

const generateEquipmentLogRows = data => {
    return data
        .map(
            item => `
        <tr>
            <td style="text-align: left">${
                item.description ? item.description : ''
            }</td>
            <td style="text-align: center;">${
                item.hoursUsed ? Number(item.hoursUsed || 0).toFixed(1) : ''
            }</td>
            <td style="text-align: center;">${
                item.timeDelivered
                    ? /^[+-]?(\d+(\.\d*)?|\.\d+)$/.test(item.timeDelivered)
                        ? Number(item.timeDelivered || 0).toFixed(1)
                        : item.timeDelivered
                    : ''
            }</td>
            <td style="text-align: center;">${
                item.timeRemoved
                    ? /^[+-]?(\d+(\.\d*)?|\.\d+)$/.test(item.timeRemoved)
                        ? Number(item.timeRemoved || 0).toFixed(1)
                        : item.timeRemoved
                    : ''
            }</td>
            <td style="text-align: left;">${item.notes ? item.notes : '-'}</td>
        </tr>`
        )
        .join('')
}

const generateMaterialDeliveryRows = (currency, data, report) => {
    return data
        .map(item => {
            let row = '<tr>'

            if (report?.materialDeliveryOptions?.description !== false) {
                row += `<td style="text-align: left;">${item.description ||
                    ''}</td>`
            }

            if (report?.materialDeliveryOptions?.quantity !== false) {
                row += `<td style="text-align: center;">${
                    item.qty ? Number(item.qty).toFixed(1) : ''
                }</td>`
            }

            if (report?.materialDeliveryOptions?.unit !== false) {
                row += `<td style="text-align: center;">${item.unit || ''}</td>`
            }

            if (report?.materialDeliveryOptions?.unitCost !== false) {
                row += `<td style="text-align: center;">${
                    item.unitCost ? formatCurrency(currency, item.unitCost) : ''
                }</td>`
            }

            if (report?.materialDeliveryOptions?.totalCost !== false) {
                row += `<td style="text-align: center;">${
                    item.qty && item.unitCost
                        ? formatCurrency(
                              currency,
                              Number(item.qty) * Number(item.unitCost)
                          )
                        : ''
                }</td>`
            }

            if (report?.materialDeliveryOptions?.notes !== false) {
                row += `<td style="text-align: left;">${item.notes || '-'}</td>`
            }

            row += '</tr>'
            return row
        })
        .join('')
}

const generateActivityLogRows = data => {
    return data
        .map(
            item => `
        <tr>
            <td style="text-align: left">${item.activity}</td>
            <td style="text-align: center;">${Number(
                item.percentage || 0
            ).toFixed(1)}%</td>
            <td style="text-align: left;">${item.notes || '-'}</td>
        </tr>`
        )
        .join('')
}

const generateAttachemntItems = files => {
    let filesString = ''
    for (const file of files) {
        filesString += `<a href="${file.url}" target="_blank">
            <img src="${
                file.isPDF ? file.firstPageURL : file.url
            }" width="100px" height="100px" />
        </a>`
    }
    return filesString
}

const formatCurrency = (currency, value) => {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
    }).format(Number(value))
}

const createPdfPreview = async pdfBytes => {
    // Load pdf doc
    const loadingTask = window.pdfjsLib.getDocument({ data: pdfBytes })
    const pdf = await loadingTask.promise

    // get first page
    const page = await pdf.getPage(1)

    // config canvas
    const viewport = page.getViewport({ scale: 1.5 })
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width

    // render page in canvas
    const renderContext = {
        canvasContext: context,
        viewport: viewport,
    }

    await page.render(renderContext).promise

    // canvas to base64
    return canvas.toDataURL('image/png')
}

export const loadPDFPreview = async url => {
    const pdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const previewUrl = await createPdfPreview(pdfBytes)
    return previewUrl
}
